import { Box, HStack, Skeleton, Text } from "@chakra-ui/react";
import { useTransferTokens } from "../context/TransferTokenContext";
import { useTransferContext } from "../context/TransferContext";
import { useTransferckBTCContext } from "../context/TransferckBTCContext";
import { formatUnits, readableNumber } from "../utils/format";
import { useHubContext } from "../context/OmnityHubContext";
import { AssetType, TabAction, Token } from "@types";
import { useBurnContext } from "../context/BurnContext";
import { useBurnTokens } from "../context/BurnTokenContext";

export default function TokenBalance() {
  const { tabAction, assetType } = useHubContext();
  if (assetType === AssetType.ckbtc) {
    return <TransferckBTCBalance />;
  }
  if (tabAction === TabAction.Burn) {
    return <BurnTokenBalance />;
  } else if (tabAction === TabAction.Transfer) {
    return <TransferTokenBalance />;
  }
}

function BurnTokenBalance() {
  const { token, onAmountChange } = useBurnContext();
  const { isLoading } = useBurnTokens();
  return (
    <BaseTokenBalance
      token={token}
      onAmountChange={onAmountChange}
      isLoading={isLoading}
    />
  );
}

function TransferTokenBalance() {
  const { token, onAmountChange } = useTransferContext();
  const { isLoading } = useTransferTokens();
  return (
    <BaseTokenBalance
      token={token}
      onAmountChange={onAmountChange}
      isLoading={isLoading}
    />
  );
}

function TransferckBTCBalance() {
  const { token, onAmountChange } = useTransferckBTCContext();

  return (
    <BaseTokenBalance
      token={token}
      onAmountChange={onAmountChange}
      isLoading={false}
      digits={8}
    />
  );
}

function BaseTokenBalance({
  token,
  onAmountChange,
  isLoading,
  digits = 3,
}: {
  token?: Token;
  onAmountChange: (amount: string) => void;
  isLoading: boolean;
  digits?: number;
}) {
  if (isLoading) {
    return <Skeleton w={81} h={23} isLoaded={false} />;
  }

  return (
    <HStack>
      <Text>
        {!!token
          ? readableNumber(formatUnits(token?.balance, token?.decimals), digits)
          : "-"}
      </Text>
      <Box bg="#008cd5" px={2} borderRadius={4} cursor="pointer">
        <Text
          color="white"
          fontSize={14}
          fontWeight={600}
          onClick={() => {
            if (token) {
              if (token.balance > BigInt(token.fee)) {
                onAmountChange(
                  formatUnits(
                    token.balance - BigInt(token.fee),
                    token?.decimals,
                  ),
                );
              } else {
                onAmountChange("0");
              }
            }
          }}
        >
          Max
        </Text>
      </Box>
    </HStack>
  );
}
