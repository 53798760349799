import { useMemo } from "react";
import { useTransferContext } from "../context/TransferContext";
import { useHubContext } from "../context/OmnityHubContext";
import ServiceFactory from "../services/Factory";

export default function useTransferService() {
  const { chains } = useHubContext();
  const { sourceChain, targetChain, token } = useTransferContext();

  const result = useMemo(() => {
    const _sourceChains = chains.filter((c) => c.chain_name === sourceChain);
    const _targetChains = chains.filter((c) => c.chain_name === targetChain);
    const _sourceServices = _sourceChains.map((c) =>
      ServiceFactory.createService(c),
    );

    let _sourceService = null;
    let _targetService = null;

    if (_sourceServices.length > 0 && _sourceServices[0]) {
      _sourceService = _sourceServices[0];
      if (token) {
        const tmp = _sourceServices.find(
          (s) => s.chain.chain_id === token.chain_id,
        );
        if (tmp) {
          _sourceService = tmp;
        }
      }
    }
    if (_targetChains.length > 0 && _targetChains[0]) {
      const _targetChain = _targetChains.find((tc) =>
        tc.token_list?.some((t) => t.token_id === token?.token_id),
      );
      _targetService = ServiceFactory.createService(
        _targetChain ?? _targetChains[0],
      );
    }

    return {
      sourceService: _sourceService,
      targetService: _targetService,
      sourceServices: _sourceServices,
    };
  }, [sourceChain, targetChain, chains.length, token?.id]);

  return result;
}
