import useTransferService from "@hooks/useTransferService";
import { useTransferContext } from "../../context/TransferContext";
import BaseSubmit from "./BaseSubmit";
import useMintService from "@hooks/useMintService";
import { useMintContext } from "../../context/MintContext";
import useBurnService from "@hooks/useBurnService";
import { useBurnContext } from "../../context/BurnContext";
import { useAddRunesContext } from "../../context/AddRunesContext";
import useAddRunesService from "@hooks/useAddRunesService";
import { useTransferckBTCContext } from "../../context/TransferckBTCContext";
import useTransferckBTCService from "@hooks/useTransferckBTCService";

export function SubmitTransferCkBTC() {
  const { sourceChain, submitRequest } = useTransferckBTCContext();
  const { sourceService } = useTransferckBTCService();
  return (
    <BaseSubmit
      chainName={sourceChain}
      submitRequest={submitRequest}
      service={sourceService}
    />
  );
}

export function SubmitAddRunes() {
  const { executeChain, submitRequest } = useAddRunesContext();
  const { executeService } = useAddRunesService();

  return (
    <BaseSubmit
      chainName={executeChain}
      submitRequest={submitRequest}
      service={executeService}
    />
  );
}

export function SubmitBurn() {
  const { burnChain, submitRequest } = useBurnContext();
  const { burnService } = useBurnService();

  return (
    <BaseSubmit
      chainName={burnChain}
      submitRequest={submitRequest}
      service={burnService}
    />
  );
}

export function SubmitMint() {
  const { mintChain, submitRequest } = useMintContext();
  const { executeService } = useMintService();

  return (
    <BaseSubmit
      chainName={mintChain}
      submitRequest={submitRequest}
      service={executeService}
    />
  );
}

export function SubmitTransfer() {
  const { sourceChain, submitRequest } = useTransferContext();
  const { sourceService } = useTransferService();

  return (
    <BaseSubmit
      chainName={sourceChain}
      submitRequest={submitRequest}
      service={sourceService}
    />
  );
}
