import {
  ChainName,
  ChainState,
  OmnityWidgetProps,
  SubmitRequire,
  Token,
} from "../types";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import { parseAmount } from "../utils/format";
import { useHubContext } from "./OmnityHubContext";
import { getChainAddr, isEvmChain } from "../utils/chains";
import { useEVMWalletKit } from "@wallet-kits/evm-wallet-kit";

interface BurnContextProps {
  burnChain: ChainName;
  burnAddr?: string;
  amount: string;
  token?: Token;
  passedProps?: OmnityWidgetProps;
  submitRequest?: SubmitRequire;
  onAmountChange: (amount: string) => void;
  onTokenChange: (token?: Token) => void;
  onBurnChainChange: (chain?: ChainName) => void;
}

const initialState: BurnContextProps = {
  burnChain: ChainName.ICP,
  burnAddr: "",
  amount: "",
  token: undefined,
  submitRequest: undefined,
  onAmountChange: () => {},
  onTokenChange: () => {},
  onBurnChainChange: () => {},
};

const BurnContext = createContext<BurnContextProps>(initialState);

export function useBurnContext() {
  return useContext(BurnContext);
}

export function BurnProvider(
  props: OmnityWidgetProps & {
    children: ReactNode;
  },
) {
  const { tokenIds } = props;

  const passedProps = {
    tokenIds,
  };
  const [amount, setAmount] = useState("");
  const [burnChain, setBurnChain] = useState<ChainName>(initialState.burnChain);
  const [selectedToken, setSelectedToken] = useState<Token>();

  const { chains, addresses } = useHubContext();
  const { chainId: evmChainId } = useEVMWalletKit();

  const burnAddr = getChainAddr(addresses, burnChain);

  const submitRequest = useMemo(() => {
    if (!burnChain) {
      return SubmitRequire.Select_Burn_Chain;
    } else {
      if (!burnAddr) {
        return SubmitRequire.Connect_Source_Chain_Wallet;
      }
      if (isEvmChain(burnChain)) {
        const evmChain = chains.find(
          (c) => c.chain_name.toLowerCase() === burnChain.toLowerCase(),
        );
        if (evmChain && evmChain.evm_chain?.id !== evmChainId) {
          return SubmitRequire.Wrong_Network;
        }
      }
    }

    const sourceChainActive = chains.find(
      (chain) =>
        chain.chain_name.toLowerCase() === burnChain.toLowerCase() &&
        chain.chain_state === ChainState.Active,
    );
    if (!sourceChainActive) {
      return SubmitRequire.Source_Chain_Not_Active;
    }

    if (!selectedToken) return SubmitRequire.Select_Token;

    if (!amount) return SubmitRequire.Enter_Amount;

    if (
      (selectedToken.balance ?? 0n) <
      parseAmount(amount, selectedToken.decimals)
    ) {
      return SubmitRequire.Insufficient_Balance;
    }

    return SubmitRequire.Confirm;
  }, [selectedToken, amount, burnChain, burnAddr, evmChainId, chains]);

  const onBurnChainChange = useCallback((chainName?: ChainName) => {
    if (chainName) {
      setBurnChain(chainName);
    }
  }, []);

  const contextValue = useMemo(() => {
    return {
      burnChain,
      burnAddr,
      amount,
      token: selectedToken,
      submitRequest,
      onAmountChange: setAmount,
      onTokenChange: setSelectedToken,
      onBurnChainChange,
      passedProps,
    };
  }, [
    burnChain,
    burnAddr,
    amount,
    selectedToken,
    submitRequest,
    onBurnChainChange,
  ]);

  return (
    <BurnContext.Provider value={contextValue}>
      {props.children}
    </BurnContext.Provider>
  );
}
