import { useMemo } from "react";
import { useAddRunesContext } from "../context/AddRunesContext";
import { useHubContext } from "../context/OmnityHubContext";
import ServiceFactory from "../services/Factory";
import { ServiceType } from "@types";

export default function useAddRunesService() {
  const { chains } = useHubContext();
  const { executeChain } = useAddRunesContext();

  const result = useMemo(() => {
    const _executeChains = chains.filter(
      (c) =>
        c.chain_name === executeChain && c.service_type === ServiceType.Route,
    );
    const _executeServices = _executeChains.map((c) =>
      ServiceFactory.createService(c),
    );

    let _executeService = null;

    if (_executeServices.length > 0 && _executeServices[0]) {
      _executeService = _executeServices[0];
    }

    return {
      executeService: _executeService,
      executeServices: _executeServices,
    };
  }, [executeChain, chains.length]);

  return result;
}
