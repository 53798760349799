import { ReactNode, useCallback, useEffect, useState } from "react";
import PubSub from "pubsub-js";
import { EventType } from "../types";
import { BTCWalletKitModal } from "..";
import {
  LaserEyesProvider,
  MAINNET,
  UNISAT,
  useLaserEyes,
} from "@omnisat/lasereyes";
import * as bitcoin from "bitcoinjs-lib";

interface TransferRunesParams {
  wallet_address: string;
  receiver_address: string;
  rune_name: string;
  fee_rate: number;
  amount: string;
  platform_fee?: bigint;
  platform_fee_collector?: string;
}

interface TransferBrc20Params {
  sender: string;
  receiver: string;
  tick: string;
  fee_rate: number;
  amount: string;
  deposit_addr: string;
  deposit_public: string;
  platform_fee?: number;
  platform_fee_collector?: string;
}

interface BTCWalletKitProviderProps {
  address: string;
  onShowModal: () => void;
  onHideModal: () => void;
  onDisconnect: () => void;
  transferRunes: (params: TransferRunesParams) => Promise<string>;
  transferBrc20: (params: TransferBrc20Params) => Promise<string>;
}

export function BTCWalletProvider({ children }: { children: ReactNode }) {
  return (
    <LaserEyesProvider config={{ network: MAINNET }}>
      {children}
      <BTCWalletKitModal />
    </LaserEyesProvider>
  );
}

const bitcoinIndexerApi = "https://mempool.space/api";

async function broadcastTx(content: string, isPsbt: boolean = true) {
  let rawTxHex = content;
  if (isPsbt) {
    const psbt = bitcoin.Psbt.fromBase64(content);
    const transaction = psbt.extractTransaction();
    rawTxHex = transaction.toHex();
  }

  const result = await fetch(`${bitcoinIndexerApi}/tx`, {
    method: "POST",
    headers: {
      "Content-Type": "text/plain",
      accept: "application/json",
    },
    body: rawTxHex,
  }).then((res) => res.text());

  return result;
}

async function getTxStatus(hash: string): Promise<"success" | "pending"> {
  try {
    if (!hash) {
      throw new Error("Transaction hash is required");
    }
    const tx = await fetch(`${bitcoinIndexerApi}/tx/${hash}`).then((res) =>
      res.json(),
    );
    return !!tx ? "success" : "pending";
  } catch (error) {
    return "pending";
  }
}

async function pollStatus(txid: string, interval = 1000, timeout = 60000) {
  const startTime = Date.now();

  return new Promise((resolve, reject) => {
    const poll = async () => {
      if (Date.now() - startTime >= timeout) {
        return reject(new Error("Checking tx status timed out"));
      }

      const status = await getTxStatus(txid);
      if (status === "success") {
        return resolve(true);
      }

      setTimeout(poll, interval);
    };

    poll();
  });
}

// eslint-disable-next-line react-refresh/only-export-components
export function useBTCWalletKit() {
  const {
    address,
    connect,
    disconnect,
    signPsbt,
    provider,
    getBalance,
    sendBTC,
  } = useLaserEyes();
  const [balance, setBalance] = useState(0n);

  useEffect(() => {
    if (address) {
      getBalance().then((balance) => {
        setBalance(BigInt(balance));
      });
    }
  }, [address]);

  const onShowModal = useCallback(() => {
    PubSub.publish(EventType.ON_SHOW_MODAL, {});
  }, []);

  const onHideModal = useCallback(() => {
    PubSub.publish(EventType.ON_HIDE_MODAL, {});
  }, []);

  const transferRunes = async (params: TransferRunesParams) => {
    await connect(provider);
    const result = await fetch(
      "https://runemint.mainnet.octopus.network/send",
      {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json",
        },
      },
    ).then((res) => res.json());

    let psbtHex;
    if (result.code === 0) {
      psbtHex = result.data;
    } else {
      throw new Error(result.data);
    }

    const signedPsbt = await signPsbt(psbtHex, true, false);
    if (signedPsbt?.signedPsbtBase64) {
      return broadcastTx(signedPsbt.signedPsbtBase64);
    }
    throw new Error("Failed to sign PSBT");
  };

  const transferBrc20 = async (
    params: TransferBrc20Params,
    setStep: (step: number) => void,
  ) => {
    await connect(provider);
    const response = await fetch(
      "https://brc20-mint.mainnet.octopus.network/build",
      {
        method: "POST",
        body: JSON.stringify(params),
        headers: {
          "Content-Type": "application/json",
        },
      },
    ).then((res) => res.json());

    if (response.code !== 200) {
      throw new Error(response.err_msg);
    }
    const [inscribePsbt, revealTx, transferPsbt] = response.data;

    const signedInscribePsbt = await signPsbt(inscribePsbt, true, false);
    if (signedInscribePsbt?.signedPsbtBase64) {
      await broadcastTx(signedInscribePsbt.signedPsbtBase64);
      const revealTxid = await broadcastTx(revealTx, false);

      setStep && setStep(1);

      if (!(provider === UNISAT)) {
        await pollStatus(revealTxid);
        await new Promise((r) => setTimeout(r, 5000));
      }

      const signedTransferPsbt = await signPsbt(transferPsbt, true, false);
      if (signedTransferPsbt?.signedPsbtBase64) {
        setStep && setStep(2);
        return broadcastTx(signedTransferPsbt.signedPsbtBase64);
      }
      throw new Error("Failed to sign transfer PSBT");
    }

    throw new Error("Failed to sign inscribe PSBT");
  };

  return {
    address,
    onShowModal,
    onHideModal,
    onDisconnect: disconnect,
    transferRunes,
    transferBrc20,
    balance,
    sendBTC,
  };
}
