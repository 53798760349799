import {
  ChainName,
  ChainState,
  OmnityWidgetProps,
  SubmitRequire,
  Token,
} from "../types";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { parseAmount } from "../utils/format";
import { useHubContext } from "./OmnityHubContext";
import {
  getAvailableChainName,
  getChainAddr,
  isEvmChain,
} from "../utils/chains";
import ServiceFactory from "@services/Factory";
import { useEVMWalletKit } from "@wallet-kits/evm-wallet-kit";

interface Fee {
  fee: bigint;
  decimals: number;
  symbol: string;
}

interface TransferContextProps {
  inputSourceChain?: ChainName;
  inputTargetChain?: ChainName;
  sourceChain?: ChainName;
  targetChain?: ChainName;
  sourceAddr?: string;
  targetAddr?: string;
  useConnectWalletForReceiver: boolean;
  fee?: Fee;
  amount: string;
  reversible: boolean;
  isPeerToPeer: boolean;
  token?: Token;
  submitRequest?: SubmitRequire;
  passedProps?: OmnityWidgetProps;
  onAmountChange: (amount: string) => void;
  onTokenChange: (token?: Token) => void;
  onTargetAddrChange: (addr: string) => void;
  onSourceChainChange: (chain?: ChainName) => void;
  onTargetChainChange: (chain?: ChainName) => void;
  onRevert: () => void;
  onToggleConnectWalletForReceiver: () => void;
}

const initialState: TransferContextProps = {
  sourceChain: undefined,
  targetChain: undefined,
  sourceAddr: "",
  targetAddr: "",
  useConnectWalletForReceiver: true,
  fee: undefined,
  amount: "",
  isPeerToPeer: false,
  token: undefined,
  reversible: true,
  submitRequest: undefined,
  onAmountChange: () => {},
  onTokenChange: () => {},
  onTargetAddrChange: () => {},
  onSourceChainChange: () => {},
  onTargetChainChange: () => {},
  onRevert: () => {},
  onToggleConnectWalletForReceiver: () => {},
};

const TransferContext = createContext<TransferContextProps>(initialState);

export function useTransferContext() {
  return useContext(TransferContext);
}

export function TransferProvider(
  props: OmnityWidgetProps & {
    children: ReactNode;
  },
) {
  const {
    children,
    sourceChain: _sourceChain,
    targetChain: _targetChain,
    reversible = true,
    isPeerToPeer = false,
    tokenIds,
  } = props;

  const passedProps = {
    children,
    sourceChain: _sourceChain,
    targetChain: _targetChain,
    reversible,
    isPeerToPeer,
    tokenIds,
  };
  const [amount, setAmount] = useState("");
  const [targetAddr, setTargetAddr] = useState("");
  const [sourceChain, setSourceChain] = useState(_sourceChain);
  const [targetChain, setTargetChain] = useState(_targetChain);
  const [selectedToken, setSelectedToken] = useState<Token>();
  const [useConnectWalletForReceiver, setUseConnectWalletForReceiver] =
    useState(initialState.useConnectWalletForReceiver);

  const { chains, addresses, customs } = useHubContext();
  const { chainId: evmChainId } = useEVMWalletKit();

  const sourceAddr = getChainAddr(addresses, sourceChain);

  const submitRequest = useMemo(() => {
    if (!sourceChain) {
      return SubmitRequire.Select_Source_Chain;
    } else {
      if (sourceChain === targetChain) {
        return SubmitRequire.Invalid_Chain;
      }
      if (!sourceAddr) {
        return SubmitRequire.Connect_Source_Chain_Wallet;
      }
      if (isEvmChain(sourceChain)) {
        const evmChain = chains.find(
          (c) => c.chain_name.toLowerCase() === sourceChain.toLowerCase(),
        );
        if (evmChain && evmChain.evm_chain?.id !== evmChainId) {
          return SubmitRequire.Wrong_Network;
        }
      }
    }

    const sourceChainActive = chains.find(
      (chain) =>
        chain.chain_name.toLowerCase() === sourceChain.toLowerCase() &&
        chain.chain_state === ChainState.Active,
    );
    if (!sourceChainActive) {
      return SubmitRequire.Source_Chain_Not_Active;
    }

    if (!targetChain) {
      return SubmitRequire.Select_Target_Chain;
    }
    const targetChainActive = chains.find(
      (chain) =>
        chain.chain_name.toLowerCase() === targetChain.toLowerCase() &&
        chain.chain_state === ChainState.Active,
    );

    if (!targetChainActive) {
      return SubmitRequire.Target_Chain_Not_Active;
    }

    if (!selectedToken) return SubmitRequire.Select_Token;

    if (!amount) return SubmitRequire.Enter_Amount;

    const parsedAmount = parseAmount(amount, selectedToken.decimals);
    if (parsedAmount === 0n) {
      return SubmitRequire.Invalid_Amount;
    }
    if ((selectedToken.balance ?? 0n) < parsedAmount) {
      return SubmitRequire.Insufficient_Balance;
    }

    if (!targetAddr) {
      return SubmitRequire.Input_Receiving_Address;
    }
    if (!ServiceFactory.validateAddress(targetChain, targetAddr)) {
      return SubmitRequire.Invalid_Address;
    }

    return SubmitRequire.Confirm;
  }, [
    sourceChain,
    selectedToken,
    amount,
    targetChain,
    sourceAddr,
    targetAddr,
    evmChainId,
    chains,
    customs,
  ]);

  const onTargetChainChange = useCallback((chainName?: ChainName) => {
    setTargetChain(chainName);
    setTargetAddr("");
  }, []);

  const onRevert = useCallback(() => {
    if (reversible) {
      setSourceChain(targetChain);
      onTargetChainChange(sourceChain);
    }
  }, [reversible, targetChain, sourceChain]);

  const contextValue = useMemo(
    () => ({
      inputSourceChain: _sourceChain,
      inputTargetChain: _targetChain,
      sourceChain,
      targetChain,
      sourceAddr,
      targetAddr,
      useConnectWalletForReceiver,
      amount,
      reversible,
      token: selectedToken,
      submitRequest,
      passedProps,
      isPeerToPeer,
      onAmountChange: setAmount,
      onTokenChange: setSelectedToken,
      onTargetAddrChange: setTargetAddr,
      onSourceChainChange: (chain?: ChainName) => {
        if (chain) {
          const availableTargetChains = getAvailableChainName(chain, chains);
          if (targetChain && !availableTargetChains.includes(targetChain)) {
            onTargetChainChange(availableTargetChains[0]);
          }
        }
        setSourceChain(chain);
      },
      onTargetChainChange,
      onRevert,
      onToggleConnectWalletForReceiver: () => {
        setUseConnectWalletForReceiver((prev) => !prev);
      },
    }),
    [
      sourceChain,
      targetChain,
      sourceAddr,
      targetAddr,
      amount,
      isPeerToPeer,
      selectedToken,
      _sourceChain,
      _targetChain,
      reversible,
      submitRequest,
      props,
      onRevert,
      onTargetChainChange,
      useConnectWalletForReceiver,
    ],
  );

  const connectedTargetAddr = getChainAddr(addresses, targetChain);

  useEffect(() => {
    if (useConnectWalletForReceiver && connectedTargetAddr) {
      setTargetAddr(connectedTargetAddr);
    } else if (!useConnectWalletForReceiver) {
      setTargetAddr("");
    }
  }, [connectedTargetAddr, useConnectWalletForReceiver, targetChain]);

  return (
    <TransferContext.Provider value={contextValue}>
      {children}
    </TransferContext.Provider>
  );
}
