import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  useDisclosure,
  Button,
  ModalBody,
  useColorModeValue,
  useToast,
  VStack,
  Text,
  HStack,
} from "@chakra-ui/react";
import { useEffect, useState } from "react";
import {
  ChainID,
  ChainName,
  TicketInteractStep,
  OnBurnParams,
  Ticket,
  TicketAction,
  BridgeFee,
} from "../../types";
import { formatFee, parseAmount } from "../../utils/format";
import { useBurnContext } from "../../context/BurnContext";
import { useBurnTokens } from "../../context/BurnTokenContext";
import useBurnService from "@hooks/useBurnService";
import TicketTxStatus from "@components/TicketTxStatus";
import ChainLogo from "@components/common/ChainLogo";
import CloseButtonForModal from "@components/common/CloseButtonForModal";
import useTickets from "@hooks/useTickets";
import { useICPWalletKit } from "@wallet-kits/icp-wallet-kit";
import { useSOLWalletKit } from "@wallet-kits/sol-wallet-kit";
import TxSubmitButton from "@components/SubmitButton/TxSubmitButton";
import { useHubContext } from "../../context/OmnityHubContext";

export default function ConfirmBurn() {
  const { burnChain, burnAddr, token, amount, onAmountChange } =
    useBurnContext();
  const { burnService } = useBurnService();
  const { feeTokenPrices } = useHubContext();
  const { updateTokens } = useBurnTokens();
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [currentTicket, setCurrentTicket] = useState<Ticket>();
  const [interactStep, setInteractStep] = useState(TicketInteractStep.START);
  const [bridgeFee, setBridgeFee] = useState<BridgeFee>();
  const [needRetry, setNeedRetry] = useState(false);

  const toast = useToast();
  const _onClose = () => {
    onClose();
    setNeedRetry(false);
    onAmountChange("");
    setInteractStep(TicketInteractStep.START);
  };
  const textColor = useColorModeValue("gray.800", "gray.100");
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const { addTicket } = useTickets();

  const { transfer: transferICP, createActor } = useICPWalletKit();
  const { transfer: transferSOL } = useSOLWalletKit();

  useEffect(() => {
    if (burnService) {
      burnService
        .getBridgeFee(ChainID.Bitcoin, token)
        .then(setBridgeFee)
        .catch(console.error);
    }
  }, [burnService?.chain.chain_id]);

  const onConfirm = async () => {
    try {
      setNeedRetry(false);
      if (!burnChain) {
        throw new Error("Please select a source chain");
      }
      if (!burnService) {
        throw new Error("Failed to get burn service");
      }
      if (!burnAddr) {
        throw new Error("Please connect your wallet first");
      }
      if (!token) {
        throw new Error("Please select a token first");
      }

      const parsedAmount = parseAmount(amount, token.decimals);
      const params: OnBurnParams = {
        burnAddr,
        token,
        amount: parsedAmount,
        targetChainId: ChainID.Bitcoin,
      };
      if (burnChain === ChainName.ICP) {
        params.createActor = createActor;
        params.transfer = transferICP;
      } else if (burnChain === ChainName.Solana) {
        params.transfer = transferSOL;
      }

      setInteractStep(TicketInteractStep.SEND_TX);
      const ticket_id = await burnService.onBurn(params);

      let finalized = false;
      if (burnChain === ChainName.ICP) {
        finalized = true;
        setInteractStep(TicketInteractStep.COMPLETED);
      } else {
        setInteractStep(TicketInteractStep.WAIT_TX);
      }
      const _ticket: Ticket = {
        type: TicketAction.Burn,
        token: token.token_id,
        dst_chain: ChainID.Bitcoin,
        src_chain: burnService.chain.chain_id,
        ticket_id,
        decimals: token.decimals,
        symbol: token.symbol,
        amount: parsedAmount.toString(),
        receiver: "",
        sender: burnAddr,
        ticket_time: Date.now(),
        finalized,
      };
      setCurrentTicket(_ticket);

      toast({
        description: "Transaction has been sent, stay tuned",
        status: "success",
        duration: 3000,
      });
      // clean state
      setTimeout(updateTokens, 3000);
      addTicket(_ticket);
    } catch (error) {
      setNeedRetry(true);
      toast({
        description: (error as Error).message,
        status: "error",
        duration: 5000,
      });
    }
  };

  return (
    <>
      <Button
        colorScheme="blue"
        cursor="pointer"
        w="98%"
        fontSize={22}
        py={8}
        borderRadius={8}
        onClick={onOpen}
      >
        Confirm
      </Button>
      <Modal
        isCentered
        closeOnOverlayClick={false}
        isOpen={isOpen}
        onClose={_onClose}
      >
        <ModalOverlay />
        <ModalContent
          p={0}
          borderRadius={8}
          color={textColor}
          margin={{ base: 0 }}
          alignSelf={{ base: "flex-end", md: "center" }}
        >
          <CloseButtonForModal />
          <ModalHeader>Burn</ModalHeader>
          <ModalBody pb={6}>
            <VStack gap={{ base: 2, md: 1 }}>
              <ChainLogo chain={burnChain} size={120} />
              <Text fontSize={32} fontWeight="bold">
                {amount} {token?.symbol}
              </Text>
              <HStack
                w="100%"
                justifyContent="space-between"
                py={2}
                borderBottomWidth={0.5}
                borderBottomColor={borderColor}
                mb={4}
              >
                <Text>Burn Fee</Text>
                <Text fontWeight={600}>
                  {formatFee(bridgeFee, feeTokenPrices)}
                </Text>
              </HStack>

              {currentTicket && (
                <TicketTxStatus
                  service={burnService}
                  ticket={currentTicket}
                  onReverted={() => {
                    setNeedRetry(true);
                  }}
                  onGenerateTicket={() =>
                    setInteractStep(TicketInteractStep.GEN_TICKET)
                  }
                  onCompleted={(finalized: boolean) => {
                    setInteractStep(TicketInteractStep.COMPLETED);
                  }}
                />
              )}

              <TxSubmitButton
                step={interactStep}
                onConfirm={onConfirm}
                onClose={_onClose}
                ticketType={TicketAction.Burn}
                needRetry={needRetry}
              />
            </VStack>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
}
