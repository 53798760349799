import { ChakraProvider, extendTheme } from "@chakra-ui/react";
import { ChainID, ChainName, OmnityWidgetProps, TabAction } from "./types";
import { BTCWalletProvider } from "./wallet-kits/btc-wallet-kit";
import { ICPWalletProvider } from "./wallet-kits/icp-wallet-kit";
import { HubProvider } from "./context/OmnityHubContext";
import EthereumWaklletKit from "./wallet-kits/evm-wallet-kit";
import { SOLWalletProvider } from "@wallet-kits/sol-wallet-kit";
import posthog from "posthog-js";
import { OSMWalletProvider } from "@wallet-kits/osm-wallet-kit";
import CkBTC from "./CkBTC";

posthog.init("phc_UtXfkZaaPLBCmniOY2tuSDGj8bCkTiIuGp0ZnAuFBUO", {
  api_host: "https://us.i.posthog.com",
  person_profiles: "always",
});

export default function CkBTCWidget({
  assetType,
  customs = ChainID.Bitcoin,
  sourceChain = ChainName.Bitcoin,
  targetChain = ChainName.ICP,
  tokenIds,
  network = "mainnet",
  colorMode = "system",
  width = 540,
  reversible = false,
  isPeerToPeer = false,
  tab = TabAction.Transfer,
  onTabChange,
}: OmnityWidgetProps) {
  const _tab = tab ?? TabAction.Transfer;

  const mainWidget = (
    <BTCWalletProvider>
      <ICPWalletProvider>
        <EthereumWaklletKit>
          <SOLWalletProvider>
            <OSMWalletProvider>
              <HubProvider
                assetType={assetType}
                customs={customs}
                network={network}
                tab={_tab}
                onTabChange={onTabChange}
              >
                <CkBTC
                  customs={customs}
                  width={width}
                  sourceChain={sourceChain}
                  targetChain={targetChain}
                  tokenIds={tokenIds}
                  reversible={reversible}
                  isPeerToPeer={isPeerToPeer}
                  tab={_tab}
                  onTabChange={onTabChange}
                />
              </HubProvider>
            </OSMWalletProvider>
          </SOLWalletProvider>
        </EthereumWaklletKit>
      </ICPWalletProvider>
    </BTCWalletProvider>
  );

  const config = {
    initialColorMode: colorMode,
    useSystemColorMode: colorMode === "system" ? true : false,
  };

  const theme = extendTheme({ config });

  return <ChakraProvider theme={theme}>{mainWidget}</ChakraProvider>;
}
