import "@rainbow-me/rainbowkit/styles.css";

import React from "react";
import { getDefaultConfig, RainbowKitProvider } from "@rainbow-me/rainbowkit";
import { useAccount, useSwitchChain, WagmiProvider } from "wagmi";
import { QueryClientProvider, QueryClient } from "@tanstack/react-query";
import { CHAIN_LIST } from "../../utils/constants";
import { Chain } from "viem";

const evmChains = Object.values(CHAIN_LIST)
  .filter((t) => !!t.evmChain)
  .map((t) => t.evmChain) as Chain[];

const config = getDefaultConfig({
  appName: "Omnity",
  projectId: "e54b43635ea1464077a34278f46d6c40",
  chains: evmChains as any,
  ssr: true,
});

const queryClient = new QueryClient();

export default function EthereumWaklletKit({
  children,
}: {
  children: React.ReactNode;
}) {
  return (
    <WagmiProvider config={config} reconnectOnMount>
      <QueryClientProvider client={queryClient}>
        <RainbowKitProvider>{children}</RainbowKitProvider>
      </QueryClientProvider>
    </WagmiProvider>
  );
}

export function useEVMWalletKit() {
  const { address, chainId } = useAccount();
  const { switchChain } = useSwitchChain();

  return {
    address,
    chainId,
    switchChain,
  };
}
