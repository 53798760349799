import {
  HStack,
  Input,
  InputGroup,
  InputRightAddon,
  Radio,
  Text,
  VStack,
  useColorModeValue,
} from "@chakra-ui/react";
import RunesSelector from "@components/RunesSelector";
import { useAddRunesContext } from "../context/AddRunesContext";
import { useHubContext } from "../context/OmnityHubContext";
import { ChainName, ServiceType, SubmitRequire } from "@types";
import ChainLogo from "@components/common/ChainLogo";
import ItemSelected from "@components/common/ItemSelected";
import { useMemo } from "react";
import { formatFee, formatUnits } from "../utils/format";
import { ADD_RUNES_SUFFIX, ICP_DECIMALS } from "../utils/constants";
import CurrentWallet from "@components/common/CurrentWallet";
import ChainButton from "@components/common/ChainButton";
import { SubmitAddRunes } from "@components/SubmitButton/SubmitButton";

export default function AddRunes({ width }: { width?: number }) {
  const boxWrapperBg = useColorModeValue("#eee", "gray.700");
  const inputBoxWrapperBg = useColorModeValue("white", "gray.800");
  const textColor = useColorModeValue("#333", "#eee");
  const boxHoverWrapperBg = useColorModeValue("gray.200", "gray.600");
  const borderColor = useColorModeValue("gray.300", "gray.600");
  const inputTextColor = useColorModeValue("gray.800", "gray.200");

  const {
    selectedRune,
    selectedChain,
    onSelectChain,
    token,
    symbol,
    onSymbolChange,
    serviceFee,
    submitRequest,
  } = useAddRunesContext();
  const { chains, feeTokenPrices } = useHubContext();

  const _chains = useMemo(() => {
    const chainsAdded = chains
      .filter((chain) =>
        chain.token_list?.some((token) => token.token_id === token.token_id),
      )
      .sort((a, b) => a.chain_name.localeCompare(b.chain_name));
    const chainsNotAdded = chains
      .filter(
        (chain) =>
          !chain.token_list?.some((token) => token.token_id === token.token_id),
      )
      .sort((a, b) => a.chain_name.localeCompare(b.chain_name));

    return [...chainsAdded, ...chainsNotAdded].filter((chain) => {
      if (!selectedRune) {
        return false;
      }
      return true;
    });
  }, [token?.token_id, chains.length, selectedRune?.rune_id]);

  return (
    <VStack w={{ base: "100%", md: width }} gap={6} fontSize={18}>
      <VStack
        width="99%"
        p={{ base: 4, md: 6 }}
        borderRadius={8}
        gap={4}
        bg={inputBoxWrapperBg}
        color={textColor}
      >
        <VStack w="100%" alignItems="flex-start" gap={1}>
          <Text fontWeight={600}>Runes</Text>
          <RunesSelector />
        </VStack>

        <VStack w="100%" alignItems="flex-start" gap={1}>
          <Text fontWeight={600}>Select Chains</Text>

          <VStack
            w="100%"
            gap={0}
            bg={boxWrapperBg}
            borderRadius={4}
            maxH={300}
            overflowY="scroll"
          >
            {!selectedRune && (
              <HStack p={2} py={3} w="100%" justifyContent="flex-start">
                <Text fontSize={18}>Please select runes first</Text>
              </HStack>
            )}
            {_chains
              .filter((c) => c.service_type === ServiceType.Route)
              .map((chain, idx) => {
                const added = chain.token_list?.some(
                  (t) => t.token_id === token?.token_id,
                );

                const isLast = idx === _chains.length - 1;
                return (
                  <HStack
                    key={chain.canister_id}
                    w="100%"
                    py={2}
                    px={4}
                    borderBottomWidth={isLast ? 0 : 0.5}
                    borderBottomColor={borderColor}
                    justifyContent="space-between"
                    cursor={"pointer"}
                    _hover={{
                      bg: boxHoverWrapperBg,
                    }}
                    onClick={() => !added && onSelectChain?.(chain.chain_name)}
                  >
                    <HStack>
                      <ChainLogo chain={chain.chain_id} size={36} />
                      <Text fontSize={20}>{chain.chain_name}</Text>
                    </HStack>

                    {added ? (
                      <ItemSelected size={22} />
                    ) : (
                      <Radio
                        borderColor="blue.300"
                        size="lg"
                        isChecked={selectedChain === chain.chain_name}
                      />
                    )}
                  </HStack>
                );
              })}
          </VStack>
        </VStack>

        <VStack w="100%" alignItems="flex-start" gap={1}>
          <Text fontWeight={600}>Symbol</Text>
          <InputGroup size="lg">
            <Input
              color={inputTextColor}
              bg={boxWrapperBg}
              py={4}
              px={2}
              lineHeight={1}
              borderWidth={0}
              height="100%"
              outline="none"
              placeholder="Runes Symbol"
              value={symbol}
              disabled={!!token}
              onChange={(e) => {
                const value = e.target.value.trim().toUpperCase();
                if (/^[A-Z]*$/.test(value)) {
                  onSymbolChange?.(value);
                }
              }}
              _focus={{
                borderWidth: 0,
                outline: "none",
                boxShadow: "none",
              }}
            />
            {!token && (
              <InputRightAddon
                height="100%"
                bg={boxWrapperBg}
                py={3}
                borderColor={boxWrapperBg}
              >
                {ADD_RUNES_SUFFIX}
              </InputRightAddon>
            )}
          </InputGroup>
          {submitRequest === SubmitRequire.Invalid_Symbol && (
            <Text fontSize={14} color="red.400">
              {"The symbol has been taken"}
            </Text>
          )}
        </VStack>

        <VStack w="100%" alignItems="flex-start" gap={1}>
          <Text fontWeight={600}>Fee</Text>
          <VStack
            w="100%"
            alignItems="flex-start"
            bg={boxWrapperBg}
            p={2}
            borderRadius={4}
            fontSize={18}
          >
            {selectedRune ? (
              <Text>
                {formatFee(
                  {
                    symbol: "ICP",
                    fee:
                      (token && serviceFee
                        ? serviceFee?.add_chain_fee
                        : serviceFee?.add_token_fee) ?? BigInt(0),
                    decimals: 8,
                  },
                  feeTokenPrices,
                )}
              </Text>
            ) : (
              <Text>-</Text>
            )}
          </VStack>
        </VStack>

        <VStack w={{ base: "100%" }} flex={1} alignItems="flex-start" gap={1}>
          <HStack w="100%" justifyContent="space-between">
            <Text fontWeight={600}>Wallet</Text>
            <CurrentWallet chain={ChainName.ICP} isSource />
          </HStack>
          <ChainButton chainName={ChainName.ICP} isSource changable={false} />
        </VStack>
      </VStack>

      <SubmitAddRunes />
    </VStack>
  );
}
