import {
  HStack,
  Tab,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  VStack,
} from "@chakra-ui/react";
import Transfer from "./tabs/Transfer";
import TicketHistory from "./components/TicketHistory";
import Burn from "./tabs/Burn";
import { AssetType, ChainID, OmnityWidgetProps, TabAction } from "@types";
import { useHubContext } from "./context/OmnityHubContext";
import Footer from "@components/common/Footer";
import { BurnProvider } from "./context/BurnContext";
import { ChainSelectProvider } from "./context/ChainContext";
import { TransferProvider } from "./context/TransferContext";
import { TransferTokenProvider } from "./context/TransferTokenContext";
import { BurnTokenProvider } from "./context/BurnTokenContext";
import AddRunes from "./tabs/AddRunes";
import { AddRunesProvider } from "./context/AddRunesContext";
import Mint from "./tabs/Mint";
import { MintProvider } from "./context/MintContext";
import { MintTokenProvider } from "./context/MintTokenContext";

const TABS = [
  TabAction.Transfer,
  TabAction.Mint,
  TabAction.Burn,
  TabAction.AddRunes,
];

export default function Omnity({
  assetType,
  customs,
  width = 464,
  tab,
  sourceChain,
  targetChain,
  tokenIds,
  reversible,
  isPeerToPeer,
  onTabChange,
}: OmnityWidgetProps) {
  const { onTabActionChange } = useHubContext();

  let tabs = TABS;
  if (assetType !== AssetType.runes) {
    tabs = [TabAction.Transfer];
  }
  return (
    <VStack
      id="omnity"
      w={{ base: "100%", md: width }}
      gap="2px"
      px={{ base: 2, md: 0 }}
      mt={{ base: 6, md: 0 }}
    >
      <Tabs
        variant="soft-rounded"
        colorScheme="pink"
        w="100%"
        index={TABS.indexOf(tab ?? TabAction.Transfer)}
      >
        <TabList gap={{ base: 2, md: 4 }} pos="relative">
          {tabs.map((tab) => {
            return (
              <Tab
                key={tab}
                color="gray.100"
                onClick={() => {
                  onTabActionChange && onTabActionChange(tab);
                  onTabChange && onTabChange(tab);
                }}
                pos="relative"
              >
                {tab}
              </Tab>
            );
          })}
          <TicketHistory />
        </TabList>
        <TabPanels mt={2}>
          <TabPanel p={0}>
            <TransferProvider
              sourceChain={sourceChain}
              targetChain={targetChain}
              tokenIds={tokenIds}
              reversible={reversible}
              isPeerToPeer={isPeerToPeer}
            >
              <ChainSelectProvider>
                <TransferTokenProvider>
                  <Transfer width={width} />
                </TransferTokenProvider>
              </ChainSelectProvider>
            </TransferProvider>
          </TabPanel>
          <TabPanel p={0}>
            <MintProvider tokenIds={tokenIds} sourceChain={sourceChain}>
              <ChainSelectProvider>
                <MintTokenProvider>
                  <Mint width={width} />
                </MintTokenProvider>
              </ChainSelectProvider>
            </MintProvider>
          </TabPanel>
          <TabPanel p={0}>
            <BurnProvider tokenIds={tokenIds}>
              <ChainSelectProvider>
                <BurnTokenProvider>
                  <Burn width={width} />
                </BurnTokenProvider>
              </ChainSelectProvider>
            </BurnProvider>
          </TabPanel>
          <TabPanel p={0}>
            <AddRunesProvider>
              <AddRunes width={width} />
            </AddRunesProvider>
          </TabPanel>
        </TabPanels>
      </Tabs>
      <Footer />
    </VStack>
  );
}
