import { Stack, VStack, Text } from "@chakra-ui/react";
import {
  OmnityWidget,
  ChainName,
  TabAction,
  ChainID,
  AssetType,
} from "@omnity/widget";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { useEffect, useState } from "react";

export default function Brc20() {
  const [tab, setTab] = useState(TabAction.Transfer);
  const [searchParams] = useSearchParams();
  const sourceChain = searchParams.get("sourceChain") || ChainName.Bitcoin;
  const targetChain = searchParams.get("targetChain") || ChainName.BitLayer;
  const tokenId = searchParams.get("tokenId");

  const location = useLocation();
  const navigate = useNavigate();
  useEffect(() => {
    const tab = decodeURIComponent(location.pathname.replace("/runes/", ""));
    const valid = Object.values(TabAction).find((t) => t.toLowerCase() === tab);
    if (valid) {
      setTab((prev) => {
        if (prev !== valid) {
          navigate(`/brc20/${valid.toLowerCase()}${location.search}`, {
            replace: true,
          });
          return valid;
        }
        return prev;
      });
    }
  }, [location.pathname, location.search]);

  useEffect(() => {
    document.title = `${tab} | Omnity Network`;
  }, [tab]);

  return (
    <Stack bgSize="contain" bgRepeat="no-repeat" bgPos="0px 200px">
      <VStack id="widget" py={4} gap={0}>
        <Text fontSize={{ base: 18, md: 24 }} mb={4} fontWeight={300}>
          Omnity Hub - Brc20
        </Text>
        <OmnityWidget
          assetType={AssetType.brc20}
          customs={ChainID.BitcoinBrc20}
          sourceChain={sourceChain as ChainName}
          targetChain={targetChain as ChainName}
          tab={tab}
          onTabChange={(action) => {
            setTab(action);
            navigate(`/brc20/${action.toLowerCase()}${location.search}`, {
              replace: true,
            });
          }}
          tokenIds={tokenId ? [tokenId] : []}
          network="mainnet"
          reversible
          isPeerToPeer={false}
        />
      </VStack>
    </Stack>
  );
}
