import { Token } from "../types";
import {
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useTransferContext } from "./TransferContext";
import useTransferService from "../hooks/useTransferService";
import { DEFAULT_TOKEN, FETCH_TOKEN_INTERVAL } from "../utils/constants";
import { getChainIdFromName } from "../utils/chains";
import { useHubContext } from "../context/OmnityHubContext";

interface TokenContextProps {
  tokens: Token[];
  isLoading: boolean;
  updateTokens: () => Promise<void>;
}

const initialState: TokenContextProps = {
  tokens: [],
  isLoading: false,
  updateTokens: async () => {},
};

const TokenContext = createContext<TokenContextProps>(initialState);

export function useTransferTokens() {
  return useContext(TokenContext);
}

export function TransferTokenProvider(props: { children: ReactNode }) {
  const [tokens, setTokens] = useState<Token[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { sourceServices } = useTransferService();
  const {
    sourceChain,
    targetChain,
    sourceAddr,
    passedProps,
    token,
    onTokenChange,
  } = useTransferContext();

  const fromChains = useMemo(() => {
    return sourceServices.filter((s) => {
      if (!targetChain) {
        return true;
      }
      const targetChainIds = getChainIdFromName(targetChain);
      if (targetChainIds.length) {
        return s.chain.counterparties.some((c) => targetChainIds.includes(c));
      }
      return false;
    });
  }, [
    sourceChain,
    targetChain,
    sourceServices[0]?.chain.chain_id,
    sourceServices[0]?.chain.token_list?.length,
  ]);

  const fetchTokenList = useCallback(async () => {
    return await Promise.all(
      fromChains.map((s) => s.fetchTokens(passedProps?.tokenIds, sourceAddr)),
    ).then((res) => {
      return res.flat();
    });
  }, [
    passedProps?.tokenIds,
    sourceAddr,
    fromChains[0]?.chain.chain_id,
    fromChains[0]?.chain.token_list?.length,
    fromChains.length,
  ]);

  const { customs } = useHubContext();
  useEffect(() => {
    setTokens([]);
    if (sourceChain) {
      setIsLoading(true);
      const tokenUpdate = () => {
        fetchTokenList().then((tokens) => {
          setTokens(tokens);
          if (token) {
            const _token = tokens.find((t) => t.token_id === token.token_id);
            onTokenChange(_token);
          } else if (passedProps?.tokenIds?.length) {
            if (tokens[0]) {
              onTokenChange(tokens[0]);
            }
          } else {
            const defaultToken = tokens.find(
              (t) => t.token_id === DEFAULT_TOKEN[customs],
            );
            if (defaultToken) {
              onTokenChange(defaultToken);
            }
          }
          setIsLoading(false);
        });
      };
      tokenUpdate();

      const tick = setInterval(tokenUpdate, FETCH_TOKEN_INTERVAL);
      return () => {
        tick && clearInterval(tick);
      };
    } else {
    }
  }, [fetchTokenList, passedProps?.tokenIds, token?.token_id]);

  const updateTokens = useCallback(async () => {
    fetchTokenList().then((tokens) => {
      setTokens(tokens);
      if (token) {
        const _token = tokens.find((t) => t.token_id === token.token_id);
        if (_token) {
          onTokenChange(_token);
        }
      }
    });
  }, [fetchTokenList, token, onTokenChange]);

  const contextValue = useMemo(() => {
    return {
      tokens,
      isLoading,
      updateTokens,
    };
  }, [tokens, isLoading, updateTokens]);

  return (
    <TokenContext.Provider value={contextValue}>
      {props.children}
    </TokenContext.Provider>
  );
}
