import { useHubContext } from "./OmnityHubContext";
import { ChainID, ChainName, Rune, SubmitRequire, Token } from "@types";
import { useICPWalletKit } from "@wallet-kits/icp-wallet-kit";
import {
  ReactNode,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { getTokenByRune } from "../utils/chains";
import { SelfServiceFee } from "@services/candids/OmnityHub.did";

interface AddRunesContextProps {
  selectedRune?: Rune;
  selectedChain?: ChainName;
  executeChain: ChainName;
  serviceFee?: SelfServiceFee;
  token?: Token;
  symbol: string;
  submitRequest?: SubmitRequire;
  onSelectRune?: (rune: Rune | undefined) => void;
  onSelectChain?: (chain: ChainName | undefined) => void;
  onSymbolChange?: (symbol: string) => void;
}

const initialState: AddRunesContextProps = {
  executeChain: ChainName.ICP,
  symbol: "",
};

const AddRunesContext = createContext<AddRunesContextProps>(initialState);

export function useAddRunesContext() {
  return useContext(AddRunesContext);
}

export function AddRunesProvider(props: { children: ReactNode }) {
  const [selectedRune, setSelectedRune] = useState<Rune>();
  const [serviceFee, setServiceFee] = useState<SelfServiceFee>();
  const [symbol, setSymbol] = useState("");
  const [selectedChain, setSelectedChain] = useState<ChainName>();

  const { chains, hubService } = useHubContext();
  const { address } = useICPWalletKit();

  const token = useMemo(() => {
    return getTokenByRune(chains, selectedRune);
  }, [selectedRune?.rune_id]);

  useEffect(() => {
    if (token?.symbol) {
      setSymbol(token.symbol);
    } else {
      setSymbol("");
    }
  }, [token?.token_id]);

  useEffect(() => {
    hubService?.getSelfServiceFee().then((res) => {
      setServiceFee(res);
    });
  }, [hubService?.canisterId]);

  const submitRequest = useMemo(() => {
    if (!selectedRune) {
      return SubmitRequire.Select_Runes;
    }
    if (!selectedChain) {
      return SubmitRequire.Select_Chain;
    }
    if (!address) {
      return SubmitRequire.Connect_Source_Chain_Wallet;
    }
    if (!token) {
      if (!symbol) {
        return SubmitRequire.Input_Symbol;
      }
      const bitcoin = chains.find((c) => c.chain_id === ChainID.Bitcoin);
      if (
        bitcoin &&
        bitcoin.token_list?.some((t) => t.symbol === `${symbol}.OT`)
      ) {
        return SubmitRequire.Invalid_Symbol;
      }
    }
    return SubmitRequire.Confirm;
  }, [
    selectedRune?.rune_id,
    address,
    selectedChain,
    token?.token_id,
    symbol,
    chains.length,
  ]);

  const contextValue = useMemo(() => {
    return {
      selectedRune,
      onSelectRune: setSelectedRune,
      executeChain: initialState.executeChain,
      submitRequest,
      selectedChain,
      onSelectChain: setSelectedChain,
      token,
      symbol,
      onSymbolChange: setSymbol,
      serviceFee,
    } as AddRunesContextProps;
  }, [
    selectedRune?.rune_id,
    submitRequest,
    selectedChain,
    token?.token_id,
    symbol,
    serviceFee,
  ]);

  return (
    <AddRunesContext.Provider value={contextValue}>
      {props.children}
    </AddRunesContext.Provider>
  );
}
